import React from "react";
import {
    Table,
    ActionIcon,
    Text,
    Pagination,
    Badge
} from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { Eye } from "tabler-icons-react";
import { formatNumberWithCommas } from "../../utilities/utilities";

const DashboardTableComponent = ({ tableData, tableCount, pageNo, setFormState }) => {
    const navigate = useNavigate();

    return (
        <>
            <Table striped highlightOnHover={true}>
                <thead>
                    <tr>
                        <th>Ref no.</th>
                        <th>Notice Count</th>
                        <th>Debtor Name</th>
                        <th>Business Unit</th>
                        <th>Invoice Amount</th>
                        <th>Total Amount</th>
                        <th>Status</th>
                        <th>Ageing</th>
                        <th>&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    {tableData.length === 0 ? (
                        <tr>
                            <td colSpan={9} className="text-center">
                                <Text size={22} className="m-8">
                                    No data found
                                </Text>
                            </td>
                        </tr>
                    ) : (
                        tableData.map((row) => {

                            let statusBadge;
                            if (row.status === "Pending") {
                                statusBadge = <td><Badge color="orange">{row.status}</Badge></td>;
                            } else if (row.status === "Completed") {
                                statusBadge = <td><Badge color="green">{row.status}</Badge></td>;
                            } else if (row.status === "Settlement") {
                                statusBadge = <td><Badge color="blue">{row.status}</Badge></td>;
                            } else if (row.status === "Case Creation") {
                                statusBadge = <td><Badge color="red">{row.status}</Badge></td>;
                            } else if (row.status === "Write off") {
                                statusBadge = <td><Badge color="blue">{row.status}</Badge></td>;
                            } else if (row.status === "Active") {
                                statusBadge = <td><Badge color="green">{row.status}</Badge></td>;
                            } else {
                                statusBadge = <td><Badge color="red">Incomplete</Badge></td>;
                            }
                            return (
                                <tr key={row.id}>
                                    <td>{`recovery-${row.id}`}</td>
                                    <td className="text-right">{row.noticeCount}</td>
                                    <td>{row.debtorName}</td>
                                    <td>{row.businessUnit}</td>
                                    <td className="text-right">{formatNumberWithCommas(row.invoiceAmount)}</td>
                                    <td className="text-right">{formatNumberWithCommas(row.totalAmount)}</td>
                                    {statusBadge}
                                    <td>{row.ageing}</td>
                                    <td>
                                        <ActionIcon
                                            color="blue"
                                            title="View"
                                            onClick={() => navigate(`/app/recovery/d/${row.id}`)}
                                        >
                                            <Eye />
                                        </ActionIcon>
                                    </td>
                                </tr>
                            )
                        })
                    )}
                </tbody>
            </Table>
            {tableCount > 0 ? (
                <div className="flex flex-row justify-center my-4">
                    <Pagination
                        page={pageNo}
                        onChange={(page) => {
                            setFormState((prevState) => ({
                                ...prevState,
                                pageNo: page,
                                apiCall: true,
                            }));
                        }}
                        total={Math.ceil(tableCount / 10)}
                    />
                </div>
            ) : null}
        </>
    );
};

export default DashboardTableComponent;
