import React, { useState, useEffect } from "react";
import "tailwindcss/tailwind.css";
import {
  Table,
  Button,
  ActionIcon,
  Badge,
  Modal,
  Textarea
} from "@mantine/core";
import {
  Edit,
  Ban,
  Check,
  PlayerPause,
  PlayerPlay,
  Send,
  Minus
} from "tabler-icons-react";
import { DatePicker } from "@mantine/dates";
import { showNotification } from '@mantine/notifications';
import AddActionModal from "../../components/Recovery/AddActionModal";
import {
  getAllActions,
  disableAction,
  pauseUnpauseAction,
  executeSpecificAction
} from "../../utilities/apis/recovery";
import { apiWrapWithErrorWithData } from "../../utilities/apiHelpers";
import { formatDate } from "../../utilities/utilities";

function ActionsPage({ recoveryId, dueDetailId, isViewPage, haveActionRight = true }) {
  const [actionFormState, setActionFormState] = useState({
    isModalOpen: false,
    isEdit: false,
    actionList: [],
    id: "",
    executionDate: "",
    preferrence: "",
    emailTemplateId: null,
    emailTemplate: null,
    emailTemplateContent: {},
    emailTemplateHTML: "",
    noticeTemplateId: null,
    noticeTemplate: null,
    noticeTemplateContent: {},
    fromEmail: "",
    recoveryId,
    toEmail: [],
    ccEmail: [],
    attachments: [],
    errors: {},
  });
  const fetchActions = async () => {
    const response = await apiWrapWithErrorWithData(
      getAllActions({ recoveryId })
    );
    if (response.success) {
      setActionFormState((prevState) => ({
        ...prevState,
        actionList: response.actionList,
      }));
    }
  };
  useEffect(() => {
    fetchActions();
  }, [recoveryId]);
  const handleEditAction = (row) => {
    setActionFormState((prevState) => ({
      ...prevState,
      isModalOpen: true,
      isEdit: true,
      id: row.id,
      executionDate: new Date(row.executionDate),
      preferrence: row.preferrence,
      emailTemplateId: row.emailTemplateId,
      emailTemplate: row.emailTemplate,
      emailTemplateContent: row.emailTemplateContent,
      noticeTemplateId: row.noticeTemplateId,
      noticeTemplate: row.noticeTemplate,
      noticeTemplateContent: row.noticeTemplateContent,
      fromEmail: row.fromEmail,
      toEmail: row.toEmail,
      ccEmail: row.ccEmail,
      attachments: row.attachments,
    }));
  };
  const handleDisableAction = async (id) => {
    const response = await apiWrapWithErrorWithData(disableAction({ id }));
    if (response.success) {
      fetchActions();
    }
  };
  const [actionAuditData, setActionAuditData] = useState({
    isModalOpen: false,
    actionId: 0,
    remark: "",
    isPaused: false,
    executionDate: null
  });
  const handleActionAuditSubmit = async () => {
    const response = await apiWrapWithErrorWithData(
      pauseUnpauseAction({
        id: actionAuditData.actionId,
        remark: actionAuditData.remark,
        isPaused: actionAuditData.isPaused,
        executionDate: actionAuditData.executionDate
      })
    );
    if (response.success) {
      fetchActions();
      setActionAuditData((prevState) => ({
        ...prevState,
        isModalOpen: false,
        actionId: 0,
        remark: "",
      }));
    }
  };
  const handleSpecificActionExecution = async (data) => {
    if (!data.isExecuted && ((data.preferrence === "email" && data.isEmailLocked) || (data.preferrence === "notice" && data.isNoticeLocked && data.isEmailLocked))) {
      const response = await apiWrapWithErrorWithData(
        executeSpecificAction({
          actionId: data.id
        })
      );
      if (response.success) {
        fetchActions();
        showNotification({
          color: 'green',
          title: 'Email Sent',
          message: 'Action has been executed successfully.',
        });
      }
    } else {
      showNotification({
        color: 'red',
        title: 'Template is not locked',
        message: 'If locked then try refreshing the page.',
      });
    }
  }
  return (
    <>
      <AddActionModal
        formState={actionFormState}
        setFormState={setActionFormState}
        errors={actionFormState.errors}
        fetchActions={fetchActions}
        dueDetailId={dueDetailId}
      />
      <Modal
        overflow="inside"
        closeOnClickOutside={false}
        size="calc(50vw)"
        opened={actionAuditData.isModalOpen}
        onClose={() =>
          setActionAuditData((prevState) => ({
            ...prevState,
            isModalOpen: false,
            actionId: 0,
            remark: "",
          }))
        }
        title="Action Audit"
      >
        {!actionAuditData.isPaused && (
          <DatePicker
            required
            className="mb-2 max-w-[350px]"
            placeholder="Pick date"
            label="Confirm Execution Date"
            openDropdownOnClear={true}
            value={actionAuditData.executionDate}
            onChange={(value) =>
              setActionAuditData((prevState) => ({
                ...prevState,
                executionDate: value,
              }))
            }
            clearable
          />
        )}
        <Textarea
          minRows={6}
          required
          label="Remark"
          placeholder="Type your remark to pause and unpause the action..."
          value={actionAuditData.remark}
          onChange={(event) =>
            setActionAuditData((prevState) => ({
              ...prevState,
              remark: event.target.value,
            }))
          }
        />
        <div className="flex justify-center mt-8">
          <Button color="green" onClick={handleActionAuditSubmit}>
            Submit
          </Button>
        </div>
      </Modal>
      {actionFormState.actionList.length !== 0 && (
        <Table striped highlightOnHover={true} className="mt-4">
          <thead>
            <tr>
              <th>Reference no.</th>
              <th>Execution Date</th>
              <th>Preference</th>
              <th>Email Template</th>
              <th>Notice Template</th>
              <th>From Email</th>
              <th>Recipient</th>
              <th>Attachment</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {actionFormState.actionList.map((row) => (
              <tr
                key={row.id}
                className={row.isPaused ? "opacity-50 cursor-not-allowed" : ""}
              >
                <td>{`action-${row.id}`}</td>
                <td>{formatDate(row.executionDate)}</td>
                <td>{row.preferrence}</td>
                <td>{row.emailTemplate}</td>
                <td>{row.noticeTemplate}</td>
                <td>{row.fromEmail}</td>
                <td>
                  <div className="grid grid-cols-12 items-center">
                    <span className="col-span-2">To:</span>
                    <div className="col-span-10 flex flex-wrap max-w-xs truncate">
                      {row.toEmail.map((email, index) => (
                        <Badge
                          key={index}
                          className="text-s m-[4px] lowercase max-w-[100px] truncate"
                          title={email}
                        >
                          {email}
                        </Badge>
                      ))}
                    </div>
                  </div>
                  <div className="grid grid-cols-12 items-center">
                    <span className="col-span-2">cc:</span>
                    <div className="col-span-10 flex flex-wrap max-w-xs truncate">
                      {row.ccEmail.map((email, index) => (
                        <Badge
                          key={index}
                          className="text-s m-[4px] lowercase max-w-[100px] truncate"
                          title={email}
                        >
                          {email}
                        </Badge>
                      ))}
                    </div>
                  </div>
                </td>
                <td className="text-center">{row.attachments.length}</td>
                <td>
                  {!haveActionRight ? (
                    <div className="grid grid-cols-1">
                      <ActionIcon>
                        <Minus color="red" />
                      </ActionIcon>
                    </div>
                  ) :
                    !isViewPage && !row.isExecuted ? (
                      <div className="grid grid-cols-2 gap-2">
                        <ActionIcon
                          color="blue"
                          title="Edit"
                          onClick={() => handleEditAction(row)}
                        >
                          <Edit />
                        </ActionIcon>
                        <ActionIcon
                          color="red"
                          title="Disable"
                          onClick={() => handleDisableAction(row.id)}
                        >
                          <Ban />
                        </ActionIcon>
                      </div>
                    ) : row.isExecuted ? (
                      <div className="grid grid-cols-1">
                        <ActionIcon title="Executed">
                          <Check color="green" />
                        </ActionIcon>
                      </div>
                    ) : (
                      <div
                        className={`grid ${row.showSendButton ? "grid-cols-2 gap-2" : "grid-cols-1"}`}
                      >
                        {row.isPaused ? (
                          <ActionIcon
                            title="Unpause"
                            onClick={() =>
                              setActionAuditData((prevState) => ({
                                ...prevState,
                                isModalOpen: true,
                                actionId: row.id,
                                isPaused: false,
                                executionDate: new Date(row.executionDate)
                              }))
                            }
                          >
                            <PlayerPlay color="red" />
                          </ActionIcon>
                        ) : (
                          <ActionIcon
                            title="Pause"
                            onClick={() =>
                              setActionAuditData((prevState) => ({
                                ...prevState,
                                isModalOpen: true,
                                actionId: row.id,
                                isPaused: true,
                                executionDate: new Date(row.executionDate)
                              }))
                            }
                          >
                            <PlayerPause color="#6c757d" />
                          </ActionIcon>
                        )}
                        {row.showSendButton && (
                          <ActionIcon title="Send"
                            onClick={() => handleSpecificActionExecution(row)}
                          >
                            <Send color="red" />
                          </ActionIcon>
                        )}
                      </div>
                    )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
      {!isViewPage && (
        <div className="flex justify-center mt-8">
          <Button
            color="green"
            onClick={() =>
              setActionFormState((prevState) => ({
                ...prevState,
                isModalOpen: true,
              }))
            }
          >
            Add Action
          </Button>
        </div>
      )}
    </>
  );
}

export default ActionsPage;
