import React, { useState, useEffect } from "react";
import { Bar, Doughnut, Line } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { ActionIcon, Modal, Select, SegmentedControl } from "@mantine/core";
import { ArrowBack } from "tabler-icons-react";
import { BeatLoader } from "react-spinners";
import { apiWrapWithErrorWithData } from "../utilities/apiHelpers";
import {
    getDashboardData,
    getDashboardAgeingData,
    getDashboardBuData,
    getDashboardStatusData,
} from "../utilities/apis/recovery";
import { formatNumberWithCommas } from "../utilities/utilities";
import DashboardTableDisplay from "../components/Recovery/DashboardTableDisplay";
import DashboardTableComponent from "../components/Recovery/DashboardTableComponent";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    ChartDataLabels
);

const RecoveryDashboard = () => {
    const [dashboardData, setDashboardData] = useState({
        loading: true,
        monthlyStats: [],
        yearlyStats: [],
        ageingStats: [],
    });
    const [buData, setBuData] = useState({
        chart: {},
        loading: true,
        tableData: [],
        totalCount: 0,
        pageNo: 1,
        apiCall: false,
        monthYear: null,
        type: null,
        isYearly: false,
    });
    const [statusData, setStatusData] = useState({
        chart: {},
        loading: true,
        tableData: [],
        totalCount: 0,
        pageNo: 1,
        apiCall: false,
    });
    const [ageingData, setAgeingData] = useState({
        basedOn: "Recovery",
        type: "All",
        loading: true,
        range: null,
        isModalOpen: false,
        tableData: [],
        totalCount: 0,
        pageNo: 1,
        apiCall: false,
    });
    const viewArray = ["bar", "buDoughnut", "statusDoughnut"];
    const [viewIndex, setViewIndex] = useState(0);
    const fetchDashboardData = async () => {
        const response = await apiWrapWithErrorWithData(getDashboardData({}));
        if (response.success) {
            setDashboardData((prevState) => ({
                ...prevState,
                loading: false,
                monthlyStats: response.data.monthlyStats,
                yearlyStats: response.data.yearlyStats,
                ageingStats: response.data.ageingStats,
            }));
        }
    };
    const fetchDashboardAgeingData = async (xValue = ageingData.range) => {
        const payload = {
            xValue,
            optSelected: ageingData.type,
            pageNo: ageingData.pageNo,
            basedOn: ageingData.basedOn,
        };
        const response = await apiWrapWithErrorWithData(
            getDashboardAgeingData(payload)
        );
        if (response.success) {
            setAgeingData((prevState) => ({
                ...prevState,
                loading: false,
                tableData: response.data.tableData,
                totalCount:
                    response.data.tableData.length > 0
                        ? response.data.tableData[0].totalDataCount
                        : 0,
            }));
            setDashboardData((prevState) => ({
                ...prevState,
                ageingStats: response.data.chartData,
            }));
        }
    };
    const prepareChartData = (stats, isYearly = false) => {
        const labels = isYearly
            ? [...new Set(stats.map((stat) => new Date(stat.year).getFullYear()))]
            : stats.map((stat) =>
                new Date(stat.month).toLocaleString("default", { month: "short" })
            );

        const writeOffCounts = isYearly
            ? labels.map((year) =>
                stats
                    .filter((stat) => new Date(stat.year).getFullYear() === year)
                    .reduce((sum, stat) => sum + parseInt(stat.writeOffCount), 0)
            )
            : stats.map((stat) => parseInt(stat.writeOffCount));

        const recoveredCounts = isYearly
            ? labels.map((year) =>
                stats
                    .filter((stat) => new Date(stat.year).getFullYear() === year)
                    .reduce((sum, stat) => sum + parseInt(stat.recoveredCount), 0)
            )
            : stats.map((stat) => parseInt(stat.recoveredCount));

        const pendingCounts = isYearly
            ? labels.map((year) =>
                stats
                    .filter((stat) => new Date(stat.year).getFullYear() === year)
                    .reduce((sum, stat) => sum + parseInt(stat.pendingCount), 0)
            )
            : stats.map((stat) => parseInt(stat.pendingCount));

        const invoiceCounts = isYearly
            ? labels.map((year) =>
                stats
                    .filter((stat) => new Date(stat.year).getFullYear() === year)
                    .reduce((sum, stat) => sum + parseInt(stat.invoiceCount), 0)
            )
            : stats.map((stat) => parseInt(stat.invoiceCount));

        return {
            labels: labels,
            datasets: [
                {
                    label: "Write Off Amount",
                    data: writeOffCounts,
                    backgroundColor: "#A6CEE3",
                    borderWidth: 1,
                },
                {
                    label: "Recovered Amount",
                    data: recoveredCounts,
                    backgroundColor: "#FB9A99",
                    borderWidth: 1,
                },
                {
                    label: "Pending Amount",
                    data: pendingCounts,
                    backgroundColor: "#FDBF6F",
                    borderWidth: 1,
                },
                {
                    label: "",
                    type: "line",
                    data: invoiceCounts,
                    backgroundColor: "white",
                    borderColor: "white",
                },
            ],
        };
    };
    const handleBarClick = async (elements, isYearly = false) => {
        if (elements.length > 0) {
            const monthYear = !isYearly
                ? dashboardData.monthlyStats[elements[0].index].month
                : dashboardData.yearlyStats[elements[0].index].year;
            let datasetLabel = !isYearly
                ? chartDataMonthly.datasets[elements[0].datasetIndex].label
                : chartDataYearly.datasets[elements[0].datasetIndex].label || null;
            setBuData((prevState) => ({
                ...prevState,
                loading: true,
                pageNo: 1,
                monthYear,
                type: datasetLabel,
                isYearly,
            }));
            const response = await apiWrapWithErrorWithData(
                getDashboardBuData({
                    type: datasetLabel,
                    monthYear,
                    isYearly,
                    pageNo: buData.pageNo,
                })
            );
            if (response.success) {
                const data = {
                    labels: response.data.chartData.map((stat) => stat.name),
                    datasets: [
                        {
                            data: response.data.chartData.map((stat) => stat.count),
                            backgroundColor: [
                                "#A6CEE3",
                                "#FB9A99",
                                "#FDBF6F",
                                "#B2DF8A",
                                "#CAB2D6",
                                "#FFFF99",
                            ],
                        },
                    ],
                };
                setBuData((prevState) => ({
                    ...prevState,
                    chart: data,
                    tableData: response.data.tableData,
                    totalCount:
                        response.data.tableData.length > 0
                            ? response.data.tableData[0].totalDataCount
                            : 0,
                }));
                setViewIndex(viewIndex + 1);
            }
        }
    };
    const fetchDashboardBuPagination = async () => {
        const payload = {
            type: buData.type,
            monthYear: buData.monthYear,
            isYearly: buData.isYearly,
            pageNo: buData.pageNo,
        };
        const response = await apiWrapWithErrorWithData(
            getDashboardBuData(payload)
        );
        if (response.success) {
            setBuData((prevState) => ({
                ...prevState,
                loading: false,
                tableData: response.data.tableData,
                totalCount:
                    response.data.tableData.length > 0
                        ? response.data.tableData[0].totalDataCount
                        : 0,
            }));
        }
    };
    const handleDoughnutClick = async (elements) => {
        if (elements.length > 0) {
            const datasetLabel = buData.chart.labels[elements[0].index];
            setStatusData((prevState) => ({
                ...prevState,
                loading: true,
                pageNo: 1,
                type: datasetLabel,
            }));
            const response = await apiWrapWithErrorWithData(
                getDashboardStatusData({
                    type: datasetLabel,
                    pageNo: statusData.pageNo
                })
            );
            if (response.success) {
                const data = {
                    labels: response.data.chartData.map((stat) => stat.name),
                    datasets: [
                        {
                            data: response.data.chartData.map((stat) => stat.count),
                            backgroundColor: [
                                "#4E79A7",
                                "#F28E2B",
                                "#E15759",
                                "#76B7B2",
                                "#59A14F",
                                "#EDC948",
                            ],
                        },
                    ],
                };
                setStatusData((prevState) => ({
                    ...prevState,
                    loading: false,
                    chart: data,
                    tableData: response.data.tableData,
                    totalCount:
                        response.data.tableData.length > 0
                            ? response.data.tableData[0].totalDataCount
                            : 0,
                }));
                setViewIndex(viewIndex + 1);
            }
        }
    };
    const handleStatusPagination = async () => {
        const response = await apiWrapWithErrorWithData(
            getDashboardStatusData({
                type: statusData.type,
                pageNo: statusData.pageNo,
            })
        );
        if (response.success) {
            setStatusData((prevState) => ({
                ...prevState,
                loading: false,
                tableData: response.data.tableData,
                totalCount:
                    response.data.tableData.length > 0
                        ? response.data.tableData[0].totalDataCount
                        : 0,
            }));
        }
    };
    const handleBackClick = () => {
        console.log("Back clicked", viewArray[viewIndex - 1]);
        setViewIndex(viewIndex - 1);
    };
    const handleLineClick = async (elements) => {
        if (elements.length > 0) {
            const xValue = chartDataAgeing.labels[elements[0].index];
            setAgeingData((prevState) => ({
                ...prevState,
                loading: true,
                pageNo: 1,
                range: xValue,
                isModalOpen: true,
            }));
            fetchDashboardAgeingData(xValue);
        }
    };

    const chartDataMonthly =
        dashboardData.monthlyStats.length > 0
            ? prepareChartData(dashboardData.monthlyStats)
            : null;
    const chartDataYearly =
        dashboardData.yearlyStats.length > 0
            ? prepareChartData(dashboardData.yearlyStats, true)
            : null;
    const chartDataAgeing = {
        labels: dashboardData.ageingStats.map((stat) => stat.DateRange),
        datasets: [
            {
                label: "Total Amount",
                data: dashboardData.ageingStats.map((stat) => stat.Amount),
                fill: false,
                backgroundColor: "rgba(75, 192, 192, 0.2)",
                borderColor: "rgb(75, 192, 192)",
            },
        ],
    };

    useEffect(() => {
        fetchDashboardData();
    }, []);

    useEffect(() => {
        if (ageingData.apiCall) {
            fetchDashboardAgeingData();
            setAgeingData((prevState) => ({ ...prevState, apiCall: false }));
        }
    }, [ageingData.apiCall]);

    useEffect(() => {
        if (buData.apiCall) {
            fetchDashboardBuPagination();
            setBuData((prevState) => ({ ...prevState, apiCall: false }));
        }
    }, [buData.apiCall]);

    useEffect(() => {
        if (statusData.apiCall) {
            handleStatusPagination();
            setStatusData((prevState) => ({ ...prevState, apiCall: false }));
        }
    }, [statusData.apiCall]);

    return (
        <>
            {/* <BeatLoader size={10} color='#228be6' /> */}
            <Modal
                opened={ageingData.isModalOpen}
                onClose={() =>
                    setAgeingData((prevState) => ({
                        ...prevState,
                        isModalOpen: false,
                        type: "All",
                    }))
                }
                title="Cases"
                size="calc(80vw)"
                closeOnClickOutside={false}
                overflow="inside"
            >
                <br></br>
                <div className="flex justify-end mb-5">
                    <Select
                        placeholder="Select recovery status"
                        data={[
                            "All",
                            "Active",
                            "Pending",
                            "Write off",
                            "Settlement",
                            "Case creation",
                            "Completed",
                        ]}
                        value={ageingData.type}
                        onChange={(value) =>
                            setAgeingData((prevState) => ({
                                ...prevState,
                                type: value,
                                apiCall: true,
                                pageNo: 1,
                            }))
                        }
                    />
                </div>
                <DashboardTableComponent
                    tableData={ageingData.tableData}
                    tableCount={ageingData.totalCount}
                    pageNo={ageingData.pageNo}
                    setFormState={setAgeingData}
                />
            </Modal>
            {!dashboardData.loading && (
                <>
                    {viewArray[viewIndex] === "bar" && (
                        <>
                            <div className="grid grid-cols-2 gap-10">
                                <div>
                                    <h3 className="text-center">Monthly Stats</h3>
                                    {chartDataMonthly && (
                                        <Bar
                                            data={chartDataMonthly}
                                            options={{
                                                scales: {
                                                    x: { stacked: true },
                                                    y: {
                                                        stacked: true,
                                                        title: {
                                                            display: true,
                                                            text: "INR Amount (in Thousands)",
                                                            font: {
                                                                size: 15,
                                                                weight: "bold",
                                                            },
                                                        },
                                                        ticks: {
                                                            callback: (value) => {
                                                                return `${Math.round(value / 1000)}`;
                                                            },
                                                        },
                                                    },
                                                },
                                                barThickness: 40,
                                                plugins: {
                                                    datalabels: {
                                                        display: (value, context) => {
                                                            return value.dataset.label === "";
                                                        },
                                                        // display: true,
                                                        color: "Black",
                                                        anchor: "center",
                                                        align: "center",
                                                        font: {
                                                            size: 12,
                                                        },
                                                        formatter: (value, context) => {
                                                            if (value === 0) return "";
                                                            return `${formatNumberWithCommas(Math.round(value / 1000))}`;
                                                        },
                                                        backgroundColor: (context) => {
                                                            return context.dataset.label === ""
                                                                ? "white"
                                                                : null;
                                                        },
                                                        borderColor: (context) => {
                                                            return context.dataset.label === ""
                                                                ? "white"
                                                                : null;
                                                        },
                                                        borderWidth: (context) => {
                                                            return context.dataset.label === "" ? 2 : 0;
                                                        },
                                                        font: (context) => {
                                                            return context.dataset.label === ""
                                                                ? { weight: "bold" }
                                                                : {};
                                                        },
                                                        padding: (context) => {
                                                            return context.dataset.label === ""
                                                                ? { left: 15, right: 15 }
                                                                : {};
                                                        },
                                                    },
                                                },
                                            }}
                                        />
                                    )}
                                </div>
                                <div>
                                    <h3 className="text-center">Yearly Stats</h3>
                                    {chartDataYearly && (
                                        <Bar
                                            data={chartDataYearly}
                                            options={{
                                                scales: {
                                                    x: { stacked: true },
                                                    y: {
                                                        stacked: true,
                                                        title: {
                                                            display: true,
                                                            text: "INR Amount (in Thousands)",
                                                            font: {
                                                                size: 15,
                                                                weight: "bold",
                                                            },
                                                        },
                                                        ticks: {
                                                            callback: (value) => {
                                                                return `${Math.round(value / 1000)}`;
                                                            },
                                                        },
                                                    },
                                                },
                                                barThickness: 40,
                                                plugins: {
                                                    datalabels: {
                                                        display: (value, context) => {
                                                            return value.dataset.label === "";
                                                        },
                                                        color: "Black",
                                                        anchor: "center",
                                                        align: "center",
                                                        borderRadius: 4,
                                                        padding: 6,
                                                        font: {
                                                            size: 12,
                                                        },
                                                        formatter: (value, context) => {
                                                            if (value === 0) return "";
                                                            return `${formatNumberWithCommas(Math.round(value / 1000))}`;
                                                        },
                                                        backgroundColor: (context) => {
                                                            return context.dataset.label === ""
                                                                ? "white"
                                                                : null;
                                                        },
                                                        borderColor: (context) => {
                                                            return context.dataset.label === ""
                                                                ? "white"
                                                                : null;
                                                        },
                                                        borderWidth: (context) => {
                                                            return context.dataset.label === "" ? 2 : 0;
                                                        },
                                                        font: (context) => {
                                                            return context.dataset.label === ""
                                                                ? { weight: "bold" }
                                                                : {};
                                                        },
                                                        padding: (context) => {
                                                            return context.dataset.label === ""
                                                                ? { left: 15, right: 15 }
                                                                : {};
                                                        },
                                                    },
                                                },
                                                onClick: (evt, elements) =>
                                                    handleBarClick(elements, true),
                                            }}
                                        />
                                    )}
                                </div>
                            </div>

                            <div className="flex flex-col items-center justify-center mb-10">
                                <div className="min-w-[55%]">
                                    <div className="flex justify-between mt-10 items-center">
                                        <h3 className="text-center">Ageing Stats</h3>
                                        <SegmentedControl
                                            color="cyan"
                                            data={["Recovery", "Invoice"]}
                                            value={ageingData.basedOn}
                                            onChange={(value) =>
                                                setAgeingData((prevState) => ({
                                                    ...prevState,
                                                    basedOn: value,
                                                    apiCall: true,
                                                }))
                                            }
                                        />
                                    </div>
                                    <Line
                                        data={chartDataAgeing}
                                        options={{
                                            scales: {
                                                x: { stacked: true },
                                                y: {
                                                    stacked: true,
                                                    title: {
                                                        display: true,
                                                        text: "INR Amount (in Thousands)",
                                                        font: {
                                                            size: 15,
                                                            weight: "bold",
                                                        },
                                                    },
                                                    ticks: {
                                                        callback: (value) => {
                                                            return `${Math.round(value / 1000)}`;
                                                        },
                                                    },
                                                },
                                            },
                                            plugins: {
                                                datalabels: {
                                                    display: true,
                                                    color: "white",
                                                    anchor: "end",
                                                    align: "center",
                                                    borderRadius: 6,
                                                    backgroundColor: "teal",
                                                    padding: 7,
                                                    font: {
                                                        size: 12,
                                                    },
                                                    formatter: (value, context) => {
                                                        if (value === 0) return "";
                                                        return `${formatNumberWithCommas(Math.round(value / 1000))}`;
                                                    },
                                                },
                                            },
                                            onClick: (evt, elements) => handleLineClick(elements),
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="mt-10">
                                <DashboardTableDisplay />
                            </div>
                        </>
                    )}
                    {viewArray[viewIndex] === "buDoughnut" && (
                        <>
                            <ActionIcon onClick={handleBackClick} color="gray" title="Back">
                                <ArrowBack />
                            </ActionIcon>
                            <h3 className="text-center">Business Unit Stats</h3>
                            <div className="flex flex-col items-center justify-center">
                                <div className="min-w-[30%]">
                                    <Doughnut
                                        data={buData.chart}
                                        options={{
                                            scales: {
                                                y: {
                                                    ticks: {
                                                        display: false,
                                                    },
                                                    grid: {
                                                        display: false,
                                                    },
                                                    title: {
                                                        display: true,
                                                        text: "INR Amount (in Thousands)",
                                                        font: {
                                                            size: 15,
                                                            weight: "bold",
                                                        },
                                                    }
                                                },
                                            },
                                            barThickness: 40,
                                            plugins: {
                                                datalabels: {
                                                    display: true,
                                                    color: "Black",
                                                    anchor: "center",
                                                    align: "center",
                                                    borderRadius: 4,
                                                    padding: 6,
                                                    font: {
                                                        size: 16,
                                                    },
                                                    formatter: (value, context) => {
                                                        if (value === 0) return "";
                                                        return `${formatNumberWithCommas(Math.round(value / 1000))}`;
                                                    },
                                                },
                                            },
                                            onClick: (evt, elements) => handleDoughnutClick(elements),
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="mt-10">
                                <DashboardTableComponent
                                    tableData={buData.tableData}
                                    tableCount={buData.totalCount}
                                    pageNo={buData.pageNo}
                                    setFormState={setBuData}
                                />
                            </div>
                        </>
                    )}
                    {viewArray[viewIndex] === "statusDoughnut" && (
                        <>
                            <ActionIcon onClick={handleBackClick} color="gray" title="Back">
                                <ArrowBack />
                            </ActionIcon>
                            <h3 className="text-center">Status Stats</h3>
                            <div className="flex flex-col items-center justify-center">
                                <div className="min-w-[30%]">
                                    <Doughnut
                                        data={statusData.chart}
                                        options={{
                                            scales: {
                                                y: {
                                                    ticks: {
                                                        display: false,
                                                    },
                                                    grid: {
                                                        display: false,
                                                    },
                                                    title: {
                                                        display: true,
                                                        text: "INR Amount (in Thousands)",
                                                        font: {
                                                            size: 15,
                                                            weight: "bold",
                                                        },
                                                    }
                                                },
                                            },
                                            barThickness: 40,
                                            plugins: {
                                                datalabels: {
                                                    display: true,
                                                    color: "Black",
                                                    anchor: "center",
                                                    align: "center",
                                                    borderRadius: 4,
                                                    padding: 6,
                                                    font: {
                                                        size: 16,
                                                    },
                                                    formatter: (value, context) => {
                                                        if (value === 0) return "";
                                                        return `${formatNumberWithCommas(Math.round(value / 1000))}`;
                                                    },
                                                },
                                            },
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="mt-10">
                                <DashboardTableComponent
                                    tableData={statusData.tableData}
                                    tableCount={statusData.totalCount}
                                    pageNo={statusData.pageNo}
                                    setFormState={setStatusData}
                                />
                            </div>
                        </>
                    )}
                </>
            )}
        </>
    );
};

export default RecoveryDashboard;
