import React, { useState } from 'react';
import {
  AppShell, Header, Navbar, ActionIcon, ScrollArea
} from '@mantine/core';
import {
  Home, Paperclip,
  Bell, Template,
  LayoutSidebarLeftCollapse, LayoutSidebarRightCollapse, Snowman, ReportMoney, Plus, LetterCase, Clipboard
} from 'tabler-icons-react';
import { LinksGroup } from '../../components/NavbarLinkGroup';
import AppHeader from '../../components/Header/AppHeader';
import { useSelector } from "react-redux";
import { selectUserData } from "../../redux/selectors";

const MainApp = ({ appContent }) => {
  const currentUser = useSelector(selectUserData) || {};
  const { name } = currentUser.loginAs;

  const mockdata = [
    {
      label: 'Dashboard',
      icon: Home,
      link: '/app',
    },
    {
      label: 'Notifications',
      icon: Bell,
      link: '/app/notifications',
    },
    {
      label: 'Recovery Locker',
      icon: Paperclip,
      link: '/app/recovery/locker',
    },
    {
      label: 'Team Manager',
      icon: Snowman,
      link: '/app/service-providers',
    },
    {
      label: 'Case',
      icon: LetterCase,
      link: '/app/dispute-manager/cases'
    },
    {
      label: 'Notice',
      icon: Clipboard,
      link: '/app/dispute-manager/legal-notices'
    },
    {
      label: 'Budget Manager',
      icon: ReportMoney,
      link: '/app/budget-manager',
    }
  ];

  if (['Initiator', 'Editor'].includes(name)) {
    mockdata.splice(2, 0, {
      label: 'My Recovery',
      icon: Paperclip,
      link: '/app/recovery',
    });
  }
  if (['Initiator', 'Editor'].includes(name)) {
    mockdata.splice(3, 0, {
      label: 'New Recovery',
      icon: Plus,
      link: '/app/recovery/new',
    });
  }
  if (name === 'Editor') {
    mockdata.splice(4, 0, {
      label: 'Templates',
      icon: Template,
      initiallyOpened: true,
      links: [
        { label: 'Notice', link: '/app/templates/notice' },
        { label: 'Email', link: '/app/templates/email' },
      ],
    });
  } else if (name === 'Initiator') {
    mockdata.splice(4, 0, {
      label: 'Templates',
      icon: Template,
      initiallyOpened: true,
      links: [
        { label: 'Email', link: '/app/templates/email' },
      ],
    });
  }

  const links = mockdata.map((item) => <LinksGroup {...item} key={item.label} />);

  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);

  return (
    <AppShell
      padding="md"
      navbar={(
        <Navbar
          style={{ backgroundColor: '#F1F4FA' }}
          width={{ base: sidebarCollapsed ? 50 : 300 }}
          height="calc(100vh - 65px)"
          p="xs"
        >
          <Navbar.Section grow component={ScrollArea}>
            <div className="flex flex-col">
              <div className="flex justify-end">
                <ActionIcon color="blue" onClick={() => setSidebarCollapsed(!sidebarCollapsed)}>
                  {sidebarCollapsed
                    ? <LayoutSidebarRightCollapse />
                    : <LayoutSidebarLeftCollapse />}
                </ActionIcon>
              </div>
              {!sidebarCollapsed && <div>{links}</div>}
            </div>
          </Navbar.Section>
        </Navbar>
      )}
      header={<Header height={65}><AppHeader /></Header>}
    >
      <div style={{ backgroundColor: '#FFFFFF', minHeight: 'calc(100vh - 100px)' }}>
        {appContent}
      </div>
    </AppShell>
  );
};

export default MainApp;
