import React, { useState, useEffect, useRef } from "react";
import { ActionIcon, Button, Select } from "@mantine/core";
import {
    Minimize,
    Maximize,
    X,
    Underline,
    Bold,
    Italic,
    ArrowBackUp,
    ArrowForwardUp,
    Strikethrough,
    AlignLeft,
    AlignCenter,
    AlignRight,
    ListNumbers,
    List,
    IndentDecrease,
    IndentIncrease,
    Blockquote,
    Eraser,
    Table,
    TextSize,
} from "tabler-icons-react";
import { apiWrapWithErrorWithData } from "../../utilities/apiHelpers";
import { getSpecifcActionData } from "../../utilities/apis/recovery";

const GoogleEditor = ({ formState, setFormState }) => {

    const [uiConfig, setUiConfig] = useState({
        toggleToolbar: false,
        toggleColorPalette: false,
        selectedBgColor: "#FFFFFF",
        selectedTextColor: "#000000",
        toggleTextSizePalette: false,
        selectedTextSize: "Medium"
    });
    const [savedSelection, setSavedSelection] = useState(null);

    const [showToolbar, setShowToolbar] = useState(false);
    const [defaultContent, setDefaultContent] = useState("");
    const editorRef = useRef(null);

    const getEmailHtml = async () => {
        const payload = {
            actionId: formState.actionId,
        }
        const response = await apiWrapWithErrorWithData(getSpecifcActionData(payload));
        if (response.success) {
            setDefaultContent(response.data.emailTemplateHTML);
        }
    };
    useEffect(() => {
        if (formState.isModalOpen) {
            getEmailHtml();
        }
    }, [formState.isModalOpen]);
    const sizes = ["Small", "Medium", "Big", "Huge"];
    const sizeStyles = {
        Small: { fontSize: "12px" },
        Medium: { fontSize: "16px" },
        Big: { fontSize: "20px" },
        Huge: { fontSize: "24px" },
    };

    const colors = [
        "#000000",
        "#444444",
        "#666666",
        "#999999",
        "#CCCCCC",
        "#EEEEEE",
        "#F3F3F3",
        "#FFFFFF",
        "#FF0000",
        "#FF9900",
        "#FFFF00",
        "#00FF00",
        "#00FFFF",
        "#0000FF",
        "#9900FF",
        "#FF00FF",
        "#F4CCCC",
        "#FCE5CD",
        "#FFF2CC",
        "#D9EAD3",
        "#D0E0E3",
        "#CFE2F3",
        "#D9D2E9",
        "#EAD1DC",
        "#EA9999",
        "#F9CB9C",
        "#FFE599",
        "#B6D7A8",
        "#A2C4C9",
        "#9FC5E8",
        "#B4A7D6",
        "#D5A6BD",
        "#E06666",
        "#F6B26B",
        "#FFD966",
        "#93C47D",
        "#76A5AF",
        "#6FA8DC",
        "#8E7CC3",
        "#C27BA0",
        "#CC0000",
        "#E69138",
        "#F1C232",
        "#6AA84F",
        "#45818E",
        "#3C78D8",
        "#674EA7",
        "#A64D79",
        "#990000",
        "#B45F06",
        "#BF9000",
        "#38761D",
        "#134F5C",
        "#0B5394",
        "#351C75",
        "#741B47",
        "#660000",
        "#783F04",
        "#7F6000",
        "#274E13",
        "#0C343D",
        "#073763",
        "#20124D",
        "#4C1130",
    ];

    const saveSelection = () => {
        const selection = window.getSelection();
        if (selection.rangeCount > 0) {
            setSavedSelection(selection.getRangeAt(0));
        }
    };

    const restoreSelection = () => {
        const selection = window.getSelection();
        if (savedSelection) {
            selection.removeAllRanges();
            selection.addRange(savedSelection);
        }
    };

    const fonts = [
        { value: "Arial, sans-serif", label: "Arial" },
        { value: '"Courier New", Courier, monospace', label: "Courier New" },
        { value: "Georgia, serif", label: "Georgia" },
        { value: '"Times New Roman", Times, serif', label: "Times New Roman" },
        { value: "Verdana, sans-serif", label: "Verdana" },
    ];

    const execCommand = (command, value = null) => {
        console.log("command", command, "value", value);
        restoreSelection();
        document.execCommand(command, false, value);
    };

    const insertTable = () => {
        const rows = prompt("Enter number of rows:");
        const cols = prompt("Enter number of columns:");
        if (rows && cols) {
            let table = "<table border='1'>";
            for (let i = 0; i < rows; i++) {
                table += "<tr>";
                for (let j = 0; j < cols; j++) {
                    table += "<td>&nbsp;&nbsp;&nbsp;</td>";
                }
                table += "</tr>";
            }
            table += "</table>";
            execCommand("insertHTML", table);
        }
    };

    const toggleToolbar = () => {
        setShowToolbar(!showToolbar);
    };

    const handleSave = () => {
        if (editorRef.current) {
            const htmlContent = editorRef.current.innerHTML;
            console.log("-------------------->>>", htmlContent);
        }
    };

    useEffect(() => {
        if (editorRef.current) {
            editorRef.current.innerHTML = defaultContent;
        }
    }, [defaultContent]);

    useEffect(() => {
        if (formState.isModalOpen) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
    }, [formState.isModalOpen]);
    const handleTextSizeChange = (size) => {
        const sizeMap = {
            Small: "2",
            Medium: "3",
            Big: "6",
            Huge: "8"
        };
        execCommand("fontSize", sizeMap[size]);
        setUiConfig({
            ...uiConfig,
            selectedTextSize: size,
            toggleTextSizePalette: false,
        })
    };
    return (
        <>
            {formState.isModalOpen && (
                <div
                    className={`fixed inset-0 bg-black bg-opacity-50 flex z-[200] shadow-sm ${formState.isFullScreen
                        ? "justify-center items-center"
                        : "justify-end items-end"
                        }`}
                    style={{
                        fontFamily:
                            '"Google Sans", Roboto, RobotoDraft, Helvetica, Arial, sans-serif',
                    }}
                >
                    <div
                        className={`bg-white relative rounded-t-lg shadow-lg ${formState.isFullScreen
                            ? "h-[43rem] w-[90rem]"
                            : "h-[35rem] w-[38rem] mr-10 b-0"
                            }`}
                    >
                        <div className="flex justify-between items-center bg-[#F2F6FC] rounded-t-lg">
                            <table className="w-full">
                                <tbody>
                                    <tr>
                                        <td>
                                            <div className="font-normal text-[18px] leading-[1.55] text-black ml-4 py-2">
                                                New Message
                                            </div>
                                        </td>
                                        <td className="text-right">
                                            <div className="flex float-right gap-1 mr-2">
                                                <ActionIcon
                                                    onClick={() =>
                                                        setFormState({
                                                            ...formState,
                                                            isFullScreen: !formState.isFullScreen,
                                                        })
                                                    }
                                                >
                                                    {formState.isFullScreen ? (
                                                        <Minimize
                                                            color="#474A49"
                                                            size={20}
                                                            strokeWidth={3}
                                                        />
                                                    ) : (
                                                        <Maximize
                                                            color="#474A49"
                                                            size={20}
                                                            strokeWidth={3}
                                                        />
                                                    )}
                                                </ActionIcon>
                                                <ActionIcon
                                                    onClick={() =>
                                                        setFormState({ ...formState, isModalOpen: false })
                                                    }
                                                >
                                                    <X color="#474A49" size={20} strokeWidth={3} />
                                                </ActionIcon>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div className="px-4">
                            <div className="flex gap-2">
                                <div className="py-2">
                                    <div className="font-normal text-[1rem] leading-[1.55] text-[#9B9C9D]">
                                        Recipients
                                    </div>
                                </div>
                            </div>
                            <hr className="m-0 bg-[#D8D8D8] h-[2px] opacity-50 border-none" />
                            <div className="flex gap-2">
                                <div className="py-2">
                                    <div className="font-normal text-[1rem] leading-[1.55] text-[#9B9C9D]">
                                        Subject
                                    </div>
                                </div>
                            </div>
                            <hr className="m-0 bg-[#D8D8D8] h-[2px] opacity-50 border-none" />
                            <div>
                                {/* <div className="flex gap-2 mb-2">
                                    <Button onClick={() => execCommand('fontSize', 1)}>Small</Button>
                                    <Button onClick={() => execCommand('fontSize', 3)}>Normal</Button>
                                    <Button onClick={() => execCommand('fontSize', 5)}>Big</Button>
                                    <Button onClick={() => execCommand('fontSize', 7)}>Huge</Button>
                                    <Button onClick={() => execCommand('fontName', 'monospace')}>font check</Button>
                                    <Button onClick={() => execCommand('foreColor', 'red')}>color check</Button>
                                </div> */}
                                {/* <div className="flex gap-2 mb-2">
                                    <Select
                                        placeholder="Select font family"
                                        data={fonts}
                                        onChange={(value) => execCommand('fontName', value)}
                                    />
                                </div> */}
                                
                                <div
                                    id="editable-div"
                                    className="focus:outline-none focus-visible:outline-none rounded-md p-2 mt-2 overflow-y-auto"
                                    role="textbox"
                                    aria-label="Message Body"
                                    contentEditable={true}
                                    spellCheck={false}
                                    style={{
                                        maxHeight: formState.isFullScreen ? '27rem' : '18rem',
                                        textAlign: "left",
                                        direction: "ltr",
                                    }}
                                    ref={editorRef}
                                ></div>
                            </div>
                            <div className="absolute bottom-[6rem] z-[300] left-[3rem] bg-white">
                                {uiConfig.toggleTextSizePalette && (
                                    <div className="border border-gray-300 w-[150px] cursor-pointer"
                                        style={{
                                            boxShadow:
                                                "0 -2px 4px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.1)",
                                        }}
                                    >
                                        {sizes.map((size) => (
                                            <div
                                                key={size}
                                                className={`p-2 border-b border-gray-300 ${uiConfig.selectedTextSize === size ? "bg-gray-200 font-bol" : ""}`}
                                                style={sizeStyles[size]}
                                                onClick={() => handleTextSizeChange(size)}
                                            >
                                                {size} {uiConfig.selectedTextSize === size && <span>&#10003;</span>}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                            <div className="absolute bottom-[6rem] z-[300] left-[5rem]">
                                {uiConfig.toggleColorPalette && (
                                    <div
                                        className="flex justify-between p-2.5 bg-white border border-gray-300 rounded-sm gap-4"
                                        style={{
                                            boxShadow:
                                                "0 -2px 4px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.1)",
                                        }}
                                    >
                                        <div className="flex flex-col items-start">
                                            <div className="font-smaller text-[1rem] leading-[1.55] text-black  mb-2.5">
                                                Background color
                                            </div>
                                            <div className="grid grid-cols-8 mb-2.5">
                                                {colors.map((color, index) => (
                                                    <div
                                                        key={index}
                                                        className="w-4 h-4 cursor-pointer rounded-sm hover:border"
                                                        style={{
                                                            backgroundColor: color,
                                                            border: `1px solid ${color === uiConfig.selectedBgColor
                                                                ? "black"
                                                                : "white"
                                                                }`,
                                                        }}
                                                        onClick={() => {
                                                            setUiConfig({
                                                                ...uiConfig,
                                                                toggleColorPalette: false,
                                                                selectedBgColor: color,
                                                            });
                                                            execCommand("hiliteColor", color);
                                                        }}
                                                        onMouseOver={(e) => {
                                                            e.target.style.border = "1px solid black";
                                                        }}
                                                        onMouseOut={(e) => {
                                                            if (color === uiConfig.selectedBgColor) return;
                                                            e.target.style.border = "1px solid white";
                                                        }}
                                                    ></div>
                                                ))}
                                            </div>
                                            <input
                                                type="color"
                                                className="mt-1"
                                                value={uiConfig.selectedBgColor}
                                                onChange={(e) => {
                                                    setUiConfig({
                                                        ...uiConfig,
                                                        toggleColorPalette: false,
                                                        selectedBgColor: e.target.value,
                                                    });
                                                    execCommand("hiliteColor", e.target.value);
                                                }}
                                            />
                                        </div>
                                        <div className="flex flex-col items-start">
                                            <div className="font-smaller text-[1rem] leading-[1.55] text-black  mb-2.5">
                                                Text color
                                            </div>
                                            <div className="grid grid-cols-8 mb-2.5">
                                                {colors.map((color, index) => (
                                                    <div
                                                        key={index}
                                                        className="w-4 h-4 cursor-pointer rounded-sm hover:border"
                                                        style={{
                                                            backgroundColor: color,
                                                            border: `1px solid ${color === uiConfig.selectedTextColor
                                                                ? "black"
                                                                : "white"
                                                                }`,
                                                        }}
                                                        onClick={() => {
                                                            setUiConfig({
                                                                ...uiConfig,
                                                                toggleColorPalette: false,
                                                                selectedTextColor: color,
                                                            });
                                                            execCommand("foreColor", color);
                                                        }}
                                                        onMouseOver={(e) => {
                                                            e.target.style.border = "1px solid black";
                                                        }}
                                                        onMouseOut={(e) => {
                                                            if (color === uiConfig.selectedTextColor) return;
                                                            e.target.style.border = "1px solid white";
                                                        }}
                                                    ></div>
                                                ))}
                                            </div>
                                            <input
                                                type="color"
                                                className="mt-1"
                                                value={uiConfig.selectedTextColor}
                                                onChange={(e) => {
                                                    setUiConfig({
                                                        ...uiConfig,
                                                        toggleColorPalette: false,
                                                        selectedTextColor: e.target.value,
                                                    });
                                                    execCommand("foreColor", e.target.value);
                                                }}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                            {showToolbar && (
                                <div
                                    className="absolute flex gap-2 mb-2 h-[2.5rem] items-center bottom-[3.5rem]"
                                    style={{
                                        boxShadow:
                                            "0 -2px 4px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.1)",
                                    }}
                                >
                                    <ActionIcon onClick={() => execCommand("undo")}>
                                        <ArrowBackUp color="#474A49" size={20} strokeWidth={3} />
                                    </ActionIcon>
                                    <ActionIcon onClick={() => execCommand("redo")}>
                                        <ArrowForwardUp color="#474A49" size={20} strokeWidth={3} />
                                    </ActionIcon>
                                    |
                                    <ActionIcon
                                        onClick={() => {
                                            saveSelection();
                                            setUiConfig({
                                                ...uiConfig,
                                                toggleTextSizePalette: !uiConfig.toggleTextSizePalette,
                                            })
                                        }}>
                                        <TextSize color="#474A49" size={20} strokeWidth={3} />
                                    </ActionIcon>
                                    <ActionIcon onClick={() => execCommand("bold")}>
                                        <Bold color="#474A49" size={20} strokeWidth={3} />
                                    </ActionIcon>
                                    <ActionIcon onClick={() => execCommand("italic")}>
                                        <Italic color="#474A49" size={20} strokeWidth={3} />
                                    </ActionIcon>
                                    <ActionIcon onClick={() => execCommand("underline")}>
                                        <Underline color="#474A49" size={20} strokeWidth={3} />
                                    </ActionIcon>
                                    <ActionIcon>
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            width={34}
                                            height={34}
                                            color="#3E4245"
                                            className="cursor-pointer"
                                            title="formatting options"
                                            onClick={() => {
                                                setUiConfig({
                                                    ...uiConfig,
                                                    toggleColorPalette: !uiConfig.toggleColorPalette,
                                                });
                                                saveSelection();
                                            }}
                                        >
                                            <text x="7" y="15" fontSize="14" fontFamily="Arial">
                                                A
                                            </text>
                                            <line
                                                x1="5"
                                                y1="18"
                                                x2="19"
                                                y2="18"
                                                stroke="currentColor"
                                                strokeWidth="2"
                                            />
                                        </svg>
                                    </ActionIcon>
                                    <ActionIcon onClick={() => execCommand("strikeThrough")}>
                                        <Strikethrough color="#474A49" size={20} strokeWidth={3} />
                                    </ActionIcon>
                                    <ActionIcon onClick={() => execCommand("justifyLeft")}>
                                        <AlignLeft color="#474A49" size={20} strokeWidth={3} />
                                    </ActionIcon>
                                    <ActionIcon onClick={() => execCommand("justifyCenter")}>
                                        <AlignCenter color="#474A49" size={20} strokeWidth={3} />
                                    </ActionIcon>
                                    <ActionIcon onClick={() => execCommand("justifyRight")}>
                                        <AlignRight color="#474A49" size={20} strokeWidth={3} />
                                    </ActionIcon>
                                    <ActionIcon onClick={() => execCommand("insertOrderedList")}>
                                        <ListNumbers color="#474A49" size={20} strokeWidth={3} />
                                    </ActionIcon>
                                    <ActionIcon
                                        onClick={() => execCommand("insertUnorderedList")}
                                    >
                                        <List color="#474A49" size={20} strokeWidth={3} />
                                    </ActionIcon>
                                    <ActionIcon onClick={() => execCommand("outdent")}>
                                        <IndentDecrease color="#474A49" size={20} strokeWidth={3} />
                                    </ActionIcon>
                                    <ActionIcon onClick={() => execCommand("indent")}>
                                        <IndentIncrease color="#474A49" size={20} strokeWidth={3} />
                                    </ActionIcon>
                                    <ActionIcon
                                        onClick={() => execCommand("formatBlock", "blockquote")}
                                    >
                                        <Blockquote color="#474A49" size={20} strokeWidth={3} />
                                    </ActionIcon>
                                    <ActionIcon onClick={() => execCommand("removeFormat")}>
                                        <Eraser color="#474A49" size={20} strokeWidth={3} />
                                    </ActionIcon>
                                    <ActionIcon onClick={insertTable}>
                                        <Table color="#474A49" size={20} strokeWidth={3} />
                                    </ActionIcon>
                                </div>
                            )}

                            <div className="absolute bottom-2 flex items-center gap-2">
                                <button
                                    className="bg-[#1B61D1] text-white text-[1rem] py-2 px-4 rounded-[2rem] border-none hover:bg-blue-700 hover:shadow-xl hover:scale-105 transition-transform duration-200"
                                    onClick={handleSave}
                                >
                                    Save
                                </button>

                                <svg
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    width={34}
                                    height={34}
                                    color="#3E4245"
                                    className="cursor-pointer"
                                    title="formatting options"
                                    onClick={toggleToolbar}
                                >
                                    <text x="7" y="15" fontSize="14" fontFamily="Arial">
                                        A
                                    </text>
                                    <line
                                        x1="5"
                                        y1="18"
                                        x2="19"
                                        y2="18"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                    />
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default GoogleEditor;
